const {get_char_list} = require("./tools/char_set");
const letters =
  [
    <path d="M 250 450 L 250 400 L 300 400 L 300 150 L 250 150 L 250 100 L 100 100 L 100 0 L 0 0 L 0 450 L 250 450 Z M 100 400 L 100 150 L 200 150 L 200 400 L 100 400 Z" id="1" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 300 L 100 0 L 0 0 L 0 300 L 100 300 Z M 100 450 L 100 350 L 0 350 L 0 450 L 100 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 250 L 350 250 L 350 200 L 200 200 L 200 400 L 100 400 L 100 200 L 150 200 L 150 150 L 100 150 L 100 50 L 200 50 L 200 150 L 300 150 L 300 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 150 L 50 150 L 50 200 L 0 200 L 0 400 L 50 400 L 50 450 L 250 450 Z M 350 200 L 350 100 L 400 100 L 400 200 L 350 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 150 100 L 150 0 L 100 0 L 100 100 L 50 100 L 50 150 L 100 150 L 100 250 L 150 250 L 150 150 L 200 150 L 200 100 L 150 100 Z M 50 100 L 0 100 L 0 50 L 50 50 L 50 100 Z M 50 150 L 50 200 L 0 200 L 0 150 L 50 150 Z M 200 150 L 250 150 L 250 200 L 200 200 L 200 150 Z M 200 100 L 200 50 L 250 50 L 250 100 L 200 100 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 500 L 100 350 L 0 350 L 0 450 L 50 450 L 50 500 L 100 500 Z M 50 500 L 50 550 L 0 550 L 0 500 L 50 500 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 200 L 0 200 L 0 250 L 200 250 L 200 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 450 L 100 350 L 0 350 L 0 450 L 100 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 400 L 50 400 L 50 450 L 250 450 Z M 100 400 L 100 50 L 200 50 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 150 450 L 150 0 L 50 0 L 50 50 L 0 50 L 0 100 L 50 100 L 50 450 L 150 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 400 L 100 400 L 100 350 L 150 350 L 150 300 L 200 300 L 200 250 L 250 250 L 250 200 L 300 200 L 300 50 L 250 50 L 250 0 L 50 0 L 50 50 L 200 50 L 200 200 L 150 200 L 150 250 L 100 250 L 100 300 L 50 300 L 50 350 L 0 350 L 0 450 L 300 450 L 300 400 Z M 50 50 L 50 100 L 0 100 L 0 50 L 50 50 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 200 L 250 200 L 250 150 L 200 150 L 200 100 L 250 100 L 250 50 L 300 50 L 300 0 L 0 0 L 0 50 L 150 50 L 150 100 L 100 100 L 100 150 L 50 150 L 50 200 L 200 200 L 200 400 L 50 400 L 50 450 L 250 450 Z M 50 400 L 0 400 L 0 350 L 50 350 L 50 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 250 L 300 0 L 200 0 L 200 50 L 150 50 L 150 100 L 200 100 L 200 250 L 50 250 L 50 200 L 0 200 L 0 300 L 200 300 L 200 450 L 300 450 L 300 300 L 350 300 L 350 250 L 300 250 Z M 150 100 L 150 150 L 100 150 L 100 100 L 150 100 Z M 50 200 L 50 150 L 100 150 L 100 200 L 50 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 200 L 250 200 L 250 150 L 100 150 L 100 50 L 300 50 L 300 0 L 0 0 L 0 200 L 200 200 L 200 400 L 50 400 L 50 450 L 250 450 Z M 50 400 L 0 400 L 0 350 L 50 350 L 50 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 200 L 250 200 L 250 150 L 100 150 L 100 100 L 150 100 L 150 50 L 250 50 L 250 0 L 100 0 L 100 50 L 50 50 L 50 100 L 0 100 L 0 400 L 50 400 L 50 450 L 250 450 Z M 100 400 L 100 200 L 200 200 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 450 L 200 250 L 250 250 L 250 200 L 300 200 L 300 0 L 0 0 L 0 50 L 200 50 L 200 200 L 150 200 L 150 250 L 100 250 L 100 450 L 200 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 200 L 250 200 L 250 150 L 300 150 L 300 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 150 L 50 150 L 50 200 L 0 200 L 0 400 L 50 400 L 50 450 L 250 450 Z M 100 400 L 100 200 L 200 200 L 200 400 L 100 400 Z M 100 150 L 100 50 L 200 50 L 200 150 L 100 150 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 450 L 200 400 L 250 400 L 250 350 L 300 350 L 300 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 250 L 50 250 L 50 300 L 200 300 L 200 350 L 150 350 L 150 400 L 50 400 L 50 450 L 200 450 Z M 100 250 L 100 50 L 200 50 L 200 250 L 100 250 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 200 L 100 100 L 0 100 L 0 200 L 100 200 Z M 100 450 L 100 350 L 0 350 L 0 450 L 100 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 450 L 300 450 L 300 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 450 L 100 450 L 100 250 L 200 250 L 200 450 Z M 200 200 L 100 200 L 100 50 L 200 50 L 200 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 0 450 L 250 450 L 250 400 L 300 400 L 300 250 L 250 250 L 250 200 L 300 200 L 300 50 L 250 50 L 250 0 L 0 0 L 0 450 Z M 200 200 L 100 200 L 100 50 L 200 50 L 200 200 Z M 200 400 L 100 400 L 100 250 L 200 250 L 200 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 400 L 100 400 L 100 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 400 L 50 400 L 50 450 L 250 450 L 250 400 Z M 250 50 L 300 50 L 300 100 L 250 100 L 250 50 Z M 300 400 L 250 400 L 250 350 L 300 350 L 300 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 0 450 L 250 450 L 250 400 L 300 400 L 300 50 L 250 50 L 250 0 L 0 0 L 0 450 Z M 200 400 L 100 400 L 100 50 L 200 50 L 200 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 50 L 250 50 L 250 0 L 0 0 L 0 450 L 250 450 L 250 400 L 100 400 L 100 250 L 200 250 L 200 200 L 100 200 L 100 50 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 200 L 100 200 L 100 50 L 250 50 L 250 0 L 0 0 L 0 450 L 100 450 L 100 250 L 200 250 L 200 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 200 L 150 200 L 150 250 L 200 250 L 200 400 L 100 400 L 100 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 400 L 50 400 L 50 450 L 250 450 Z M 250 50 L 300 50 L 300 100 L 250 100 L 250 50 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 450 L 300 0 L 200 0 L 200 200 L 100 200 L 100 0 L 0 0 L 0 450 L 100 450 L 100 250 L 200 250 L 200 450 L 300 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 450 L 100 0 L 0 0 L 0 450 L 100 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 0 L 200 0 L 200 400 L 100 400 L 100 250 L 0 250 L 0 400 L 50 400 L 50 450 L 250 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 350 450 L 350 400 L 300 400 L 300 350 L 250 350 L 250 300 L 200 300 L 200 250 L 150 250 L 150 200 L 200 200 L 200 150 L 250 150 L 250 100 L 300 100 L 300 50 L 350 50 L 350 0 L 250 0 L 250 50 L 200 50 L 200 100 L 150 100 L 150 150 L 100 150 L 100 0 L 0 0 L 0 450 L 100 450 L 100 300 L 150 300 L 150 350 L 200 350 L 200 400 L 250 400 L 250 450 L 350 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 400 L 100 400 L 100 0 L 0 0 L 0 450 L 250 450 L 250 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 50 450 L 50 200 L 100 200 L 100 250 L 150 250 L 150 300 L 200 300 L 200 350 L 250 350 L 250 300 L 300 300 L 300 250 L 350 250 L 350 200 L 400 200 L 400 450 L 500 450 L 500 0 L 450 0 L 450 50 L 400 50 L 400 100 L 350 100 L 350 150 L 300 150 L 300 200 L 200 200 L 200 150 L 150 150 L 150 100 L 100 100 L 100 50 L 50 50 L 50 0 L 0 0 L 0 450 L 50 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 0 L 300 250 L 250 250 L 250 200 L 200 200 L 200 150 L 150 150 L 150 100 L 100 100 L 100 50 L 50 50 L 50 0 L 0 0 L 0 450 L 50 450 L 50 200 L 100 200 L 100 250 L 150 250 L 150 300 L 200 300 L 200 350 L 250 350 L 250 400 L 300 400 L 300 450 L 350 450 L 350 0 L 300 0 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 400 L 50 400 L 50 450 L 250 450 Z M 100 400 L 100 50 L 200 50 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 450 L 100 250 L 250 250 L 250 200 L 300 200 L 300 50 L 250 50 L 250 0 L 0 0 L 0 450 L 100 450 Z M 100 200 L 100 50 L 200 50 L 200 200 L 100 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 500 L 300 450 L 250 450 L 250 400 L 300 400 L 300 50 L 250 50 L 250 0 L 50 0 L 50 50 L 0 50 L 0 400 L 50 400 L 50 450 L 200 450 L 200 500 L 300 500 Z M 100 400 L 100 50 L 200 50 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 450 L 300 250 L 250 250 L 250 200 L 300 200 L 300 50 L 250 50 L 250 0 L 0 0 L 0 450 L 100 450 L 100 250 L 200 250 L 200 450 L 300 450 Z M 100 200 L 100 50 L 200 50 L 200 200 L 100 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 450 L 200 400 L 250 400 L 250 250 L 200 250 L 200 200 L 150 200 L 150 150 L 100 150 L 100 50 L 200 50 L 200 0 L 50 0 L 50 50 L 0 50 L 0 200 L 50 200 L 50 250 L 100 250 L 100 300 L 150 300 L 150 400 L 50 400 L 50 450 L 200 450 Z M 200 50 L 250 50 L 250 100 L 200 100 L 200 50 Z M 50 400 L 0 400 L 0 350 L 50 350 L 50 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 450 L 200 50 L 300 50 L 300 0 L 0 0 L 0 50 L 100 50 L 100 450 L 200 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 0 L 200 0 L 200 400 L 100 400 L 100 0 L 0 0 L 0 400 L 50 400 L 50 450 L 250 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 350 L 300 350 L 300 0 L 200 0 L 200 400 L 100 400 L 100 0 L 0 0 L 0 450 L 250 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 400 L 300 0 L 200 0 L 200 400 L 100 400 L 100 0 L 0 0 L 0 450 L 400 450 L 400 400 L 300 400 Z M 400 400 L 400 0 L 500 0 L 500 350 L 450 350 L 450 400 L 400 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 450 L 300 250 L 250 250 L 250 200 L 300 200 L 300 0 L 200 0 L 200 200 L 100 200 L 100 0 L 0 0 L 0 200 L 50 200 L 50 250 L 0 250 L 0 450 L 100 450 L 100 250 L 200 250 L 200 450 L 300 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 450 L 200 250 L 250 250 L 250 200 L 300 200 L 300 0 L 200 0 L 200 200 L 100 200 L 100 0 L 0 0 L 0 200 L 50 200 L 50 250 L 100 250 L 100 450 L 200 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 400 L 100 400 L 100 300 L 150 300 L 150 250 L 200 250 L 200 200 L 250 200 L 250 150 L 300 150 L 300 0 L 0 0 L 0 50 L 200 50 L 200 150 L 150 150 L 150 200 L 100 200 L 100 250 L 50 250 L 50 300 L 0 300 L 0 450 L 300 450 L 300 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 450 L 300 150 L 250 150 L 250 100 L 50 100 L 50 150 L 0 150 L 0 200 L 50 200 L 50 250 L 0 250 L 0 400 L 50 400 L 50 450 L 300 450 Z M 100 400 L 100 250 L 200 250 L 200 400 L 100 400 Z M 50 150 L 50 200 L 200 200 L 200 150 L 50 150 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 150 L 250 150 L 250 100 L 100 100 L 100 0 L 0 0 L 0 450 L 250 450 Z M 100 400 L 100 150 L 200 150 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 400 L 100 150 L 200 150 L 200 100 L 50 100 L 50 150 L 0 150 L 0 400 L 50 400 L 50 450 L 200 450 L 200 400 L 100 400 Z M 200 150 L 250 150 L 250 200 L 200 200 L 200 150 Z M 200 400 L 200 350 L 250 350 L 250 400 L 200 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 450 L 300 0 L 200 0 L 200 100 L 50 100 L 50 150 L 0 150 L 0 400 L 50 400 L 50 450 L 300 450 Z M 100 400 L 100 150 L 200 150 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 400 L 100 400 L 100 300 L 300 300 L 300 150 L 250 150 L 250 100 L 50 100 L 50 150 L 0 150 L 0 400 L 50 400 L 50 450 L 250 450 L 250 400 Z M 100 250 L 100 150 L 200 150 L 200 250 L 100 250 Z M 300 400 L 300 350 L 250 350 L 250 400 L 300 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 150 450 L 150 150 L 200 150 L 200 100 L 150 100 L 150 50 L 250 50 L 250 0 L 100 0 L 100 50 L 50 50 L 50 100 L 0 100 L 0 150 L 50 150 L 50 450 L 150 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 600 L 250 550 L 300 550 L 300 100 L 50 100 L 50 150 L 0 150 L 0 400 L 50 400 L 50 450 L 200 450 L 200 550 L 50 550 L 50 600 L 250 600 Z M 100 400 L 100 150 L 200 150 L 200 400 L 100 400 Z M 50 550 L 0 550 L 0 500 L 50 500 L 50 550 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 450 L 300 150 L 250 150 L 250 100 L 100 100 L 100 0 L 0 0 L 0 450 L 100 450 L 100 150 L 200 150 L 200 450 L 300 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 450 L 100 100 L 0 100 L 0 450 L 100 450 Z M 0 0 L 0 50 L 100 50 L 100 0 L 0 0 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 600 L 200 550 L 250 550 L 250 100 L 150 100 L 150 550 L 50 550 L 50 600 L 200 600 Z M 150 0 L 150 50 L 250 50 L 250 0 L 150 0 Z M 50 550 L 50 500 L 0 500 L 0 550 L 50 550 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 200 L 250 200 L 250 150 L 300 150 L 300 100 L 200 100 L 200 150 L 150 150 L 150 200 L 100 200 L 100 0 L 0 0 L 0 450 L 100 450 L 100 350 L 150 350 L 150 400 L 200 400 L 200 450 L 300 450 L 300 400 L 250 400 L 250 350 L 200 350 L 200 300 L 150 300 L 150 250 L 200 250 L 200 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 450 L 100 0 L 0 0 L 0 450 L 100 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 500 450 L 500 150 L 450 150 L 450 100 L 0 100 L 0 450 L 100 450 L 100 150 L 200 150 L 200 450 L 300 450 L 300 150 L 400 150 L 400 450 L 500 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 450 L 300 150 L 250 150 L 250 100 L 0 100 L 0 450 L 100 450 L 100 150 L 200 150 L 200 450 L 300 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 250 400 L 300 400 L 300 150 L 250 150 L 250 100 L 50 100 L 50 150 L 0 150 L 0 400 L 50 400 L 50 450 L 250 450 Z M 100 400 L 100 150 L 200 150 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 550 L 100 450 L 250 450 L 250 400 L 300 400 L 300 150 L 250 150 L 250 100 L 0 100 L 0 550 L 100 550 Z M 100 400 L 100 150 L 200 150 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 550 L 300 100 L 50 100 L 50 150 L 0 150 L 0 400 L 50 400 L 50 450 L 200 450 L 200 550 L 300 550 Z M 100 400 L 100 150 L 200 150 L 200 400 L 100 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 100 L 0 100 L 0 450 L 100 450 L 100 200 L 150 200 L 150 150 L 100 150 L 100 100 Z M 150 150 L 150 100 L 250 100 L 250 150 L 150 150 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 450 L 200 400 L 250 400 L 250 300 L 200 300 L 200 250 L 150 250 L 150 200 L 100 200 L 100 150 L 200 150 L 200 100 L 50 100 L 50 150 L 0 150 L 0 250 L 50 250 L 50 300 L 100 300 L 100 350 L 150 350 L 150 400 L 50 400 L 50 450 L 200 450 Z M 200 150 L 250 150 L 250 200 L 200 200 L 200 150 Z M 50 400 L 0 400 L 0 350 L 50 350 L 50 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 450 L 200 400 L 150 400 L 150 150 L 200 150 L 200 100 L 150 100 L 150 0 L 50 0 L 50 100 L 0 100 L 0 150 L 50 150 L 50 400 L 100 400 L 100 450 L 200 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 450 L 300 100 L 200 100 L 200 400 L 100 400 L 100 100 L 0 100 L 0 400 L 50 400 L 50 450 L 300 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 100 400 L 100 100 L 0 100 L 0 450 L 200 450 L 200 400 L 100 400 Z M 200 400 L 200 100 L 300 100 L 300 350 L 250 350 L 250 400 L 200 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 400 L 300 100 L 200 100 L 200 400 L 100 400 L 100 100 L 0 100 L 0 450 L 400 450 L 400 400 L 300 400 Z M 400 400 L 400 100 L 500 100 L 500 350 L 450 350 L 450 400 L 400 400 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 200 100 L 200 250 L 100 250 L 100 100 L 0 100 L 0 250 L 50 250 L 50 300 L 0 300 L 0 450 L 100 450 L 100 300 L 200 300 L 200 450 L 300 450 L 300 300 L 250 300 L 250 250 L 300 250 L 300 100 L 200 100 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 600 L 250 550 L 300 550 L 300 100 L 200 100 L 200 400 L 100 400 L 100 100 L 0 100 L 0 400 L 50 400 L 50 450 L 200 450 L 200 550 L 50 550 L 50 600 L 250 600 Z M 50 550 L 0 550 L 0 500 L 50 500 L 50 550 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 300 100 L 0 100 L 0 150 L 200 150 L 200 200 L 150 200 L 150 250 L 100 250 L 100 300 L 50 300 L 50 350 L 0 350 L 0 450 L 300 450 L 300 400 L 100 400 L 100 350 L 150 350 L 150 300 L 200 300 L 200 250 L 250 250 L 250 200 L 300 200 L 300 100 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 250 450 L 100 450 L 100 350 L 300 350 L 300 200 L 250 200 L 250 150 L 50 150 L 50 200 L 0 200 L 0 450 L 50 450 L 50 500 L 250 500 L 250 450 Z M 100 300 L 100 200 L 200 200 L 200 300 L 100 300 Z M 150 50 L 200 50 L 200 0 L 150 0 L 150 50 Z M 150 100 L 150 50 L 100 50 L 100 100 L 150 100 Z M 300 450 L 250 450 L 250 400 L 300 400 L 300 450 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 50 150 L 100 150 L 100 0 L 0 0 L 0 100 L 50 100 L 50 150 Z M 50 200 L 50 150 L 0 150 L 0 200 L 50 200 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 50 50 L 0 50 L 0 200 L 100 200 L 100 100 L 50 100 L 50 50 Z M 200 50 L 150 50 L 150 200 L 250 200 L 250 100 L 200 100 L 200 50 Z M 50 0 L 50 50 L 100 50 L 100 0 L 50 0 Z M 200 0 L 200 50 L 250 50 L 250 0 L 200 0 Z" id="0" vectorEffect="non-scaling-stroke"/>,
    <path d="M 50 150 L 100 150 L 100 0 L 0 0 L 0 100 L 50 100 L 50 150 Z M 200 150 L 250 150 L 250 0 L 150 0 L 150 100 L 200 100 L 200 150 Z M 50 200 L 50 150 L 0 150 L 0 200 L 50 200 Z M 200 200 L 200 150 L 150 150 L 150 200 L 200 200 Z" id="0" vectorEffect="non-scaling-stroke"/>
  ]
const chars = get_char_list();

export const get_path = (letter) => {
  return letters[Math.max(0,chars.indexOf(letter))]
}