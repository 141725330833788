import {useEffect, useRef, useState,useLayoutEffect} from "react";


export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function ScaleToFit({children, trigger, className}){
  const ref = useRef(null);
  const size = useWindowSize();
  const [scale, setScale] = useState(1);

  const transform = `scale(${scale})`
  useEffect(() => {
      const dialog_width = ref.current ? ref.current.offsetWidth : 1;
      const padded_window = size[0] - 40;
      if(dialog_width > padded_window) {
        const newScale = padded_window / dialog_width;
        setScale(newScale);
      } else {
        setScale(1);
      }
  }, [size, trigger]);

  return <div className={className} ref={ref} style={{transform}}>
    {children}
  </div>
}