import "./ChainView.scss"
import words from "./conv_words.json"
import {useParams} from "react-router-dom";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import opt_active from "./img/opt_active.svg";
import tez from "./img/tez.svg";
import eth from "./img/eth.svg";
import opt_flipped from "./img/opt_flipped.svg";
import tez_flipped from "./img/tez_flipped.svg";
import eth_flipped from "./img/eth_flipped.svg";
import {ScaleToFit} from "./directives/scaleToFit";
import {createSVG} from "./directives/createSVG";
import help from "./img/help.svg"
import hello from "./img/hello.svg"

import { Network, Alchemy } from 'alchemy-sdk';
import {gql, useQuery} from "@apollo/client";
const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";

const settings = {
  apiKey: "5tubKJ46rKHHULAIuc8Ih395_pjd4Y8Z",
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(settings);



const TezosData = ({iter}) => {
  const [owner, setOwner] = useState(null);
  const [fxhash_link, setfxhash_link] = useState('https://www.fxhash.xyz/generative/30058')
  const [objkt_link, setObjkt_link] = useState(null);
  const query = gql`
   query ExampleQuery($take: Int, $filters: ObjktFilter, $skip: Int){
      generativeToken(slug:"dialog-box") {
        objkts(take: $take, filters: $filters, skip: $skip){
          iteration
          slug
          id
          onChainId
          owner {
            name
            account {
              username
              wallets {
                address
                network
              }
            }
          }
        }
      }
    }
  `

  const { data, loading } = useQuery(query, {variables: {"take": 5,"skip": iter-1}});
  useEffect(() =>{
    setObjkt_link(null);
    setOwner(null);
    setfxhash_link('https://www.fxhash.xyz/generative/30058')
    if(Array.isArray(data?.generativeToken?.objkts)){
      const token = data.generativeToken.objkts.find(e => e.iteration === iter);
      if(token) {
        setOwner(token.owner.account.username)
        if(token.onChainId) {
          setfxhash_link(`https://www.fxhash.xyz/gentk/KT1EfsNuqwLAWDd3o4pvfUx1CAh5GMdTrRvr-${token.onChainId}`)
          setObjkt_link(`https://objkt.com/tokens/fxparams/${token.onChainId}`)
        }
      }
    }
  },[iter, data])

  if(loading) {
    return <div>Loading</div>
  }
  if(owner) {
    return (
      <>
        <a rel={'nofollow'} target={'_blank'} href={fxhash_link}>View On fxHash</a>
        <a rel={'nofollow'} target={'_blank'} href={objkt_link}>View On Objkt</a>
        <a rel={'nofollow'} target={'_blank'} href={`https://www.fxhash.xyz/u/${owner}`}>Owner: {owner}</a>
      </>
    )
  }
  return (
    <>
      <a rel={'nofollow'} target={'blank'} href={'https://www.fxhash.xyz/generative/30058'}>Unowned</a>
    </>
  )
}


export function ChainView({chain}) {
  window.firstload = false;
  const params = useParams();

  const [hover, setHover] = useState(false);
  const [opensea_link, setOpenseaLink] = useState(false);
  const [hash, setHash] = useState(false);
  const [owner, setOwner] = useState(false);

  const is_optimism = chain==='optimism';
  const is_ethereum = chain==='ethereum';
  const is_tezos = chain === 'tezos';
  let iter = (Number(params.iter) || 1);

  // Handle overflows (manually going to token ID 1000 for example)
  if(is_optimism && iter > 1) {
    iter = 1;
  }
  if(is_ethereum && iter === 1) {
    iter = 2;
  }
  if((is_ethereum || is_tezos) && iter > 255) {
    iter = 255;
  }

  // Offset the iter for tez
  let tez_iter = iter + 256;
  const is_first_iteration = iter === 1;

  // True when 'ethereum' is hovered on optimism
  const opt_hover = is_first_iteration && hover && is_optimism;
  let word_index = is_tezos ? tez_iter : iter;

  // Override the optimism index
  if(is_optimism) {
    word_index = 1;
  }
  const word = words[(word_index) % words.length];

  // The order of the chains when hitting the next/prev button off the end
  const chains = ['optimism', 'ethereum', 'tezos']
  let next_chain = chain;
  let prev_chain = chain;
  let next = iter + 1;
  if(next > 255 || (is_optimism && is_first_iteration)) {
    next = is_optimism ? 2 : 1;
    next_chain = chains[(chains.indexOf(chain)+1) % 3];
  }
  let prev = iter - 1;
  if(prev === 1 && is_ethereum) {
    prev_chain = 'optimism';
    prev = 1;
  }
  if(prev === 0) {
    prev_chain = chains[(chains.indexOf(chain)+2) % 3];
    prev = prev_chain === 'optimism' ? 1 : 255;
  }

  // Turn the dialog red when it's hovered
  let dialog_classes = "dialog normal run_animation"
  if(opt_hover) {
    dialog_classes = 'dialog optimism run_animation'
  }
  // let opensea_link;




  useEffect(() => {
    (async () => {
      console.log(chain, iter);
      setOwner({name: null, hash: null});
      setHash(null);
      if(chain === 'optimism') {
        setOpenseaLink( 'https://opensea.io/assets/optimism/0x58aa7eff12083cde25376da6cf685f6368253a24/1');
        setHash('0x58aa7eff12083cde25376da6cf685f6368253a24')
        return;
      }
      setOpenseaLink('loading');
      if(chain === 'ethereum') {
        const address= '0xe3da4c00a412d31ea16de031d7e572423281577e';
        try {
          const owner = await alchemy.nft.getOwnersForNft(address, iter);
          if(Array.isArray(owner?.owners) && owner.owners[0]) {
            const ens = await alchemy.nft.getNftsForOwner(owner.owners[0], {contractAddresses: [ensContractAddress]});
            setOpenseaLink(`https://opensea.io/assets/ethereum/${address}/${iter}`);
            const ens_tokens = ens.ownedNfts.filter(nft => nft.name);
            let ens_name = owner.owners[0];
            console.log(ens_tokens);
            if(ens_tokens.length > 0) {
              ens_name = ens_tokens[ens_tokens.length - 1].name.replace('.', '. ')
            }
            const owner_data = {hash: owner.owners[0], name: ens_name}
            owner_data.name = owner_data.name.replace('.', '. ')
            setOwner(owner_data);
          } else {
            setOpenseaLink(null);
          }
        } catch (e) {setOpenseaLink(null);}

      }
      if(chain === 'tezos') {
        const res2 = await fetch(`https://api.tzkt.io/v1/tokens/balances?token.contract=KT1EfsNuqwLAWDd3o4pvfUx1CAh5GMdTrRvr&token.tokenId=${169607 + iter}&balance.gt=0&select.values=account.address,balance`)
        const cont = await res2.json();
        if (!Array.isArray(cont) || cont.length === 0 || !Array.isArray(cont[0]) || cont[0][0].length === 0) {
          setOwner(null);
          setOpenseaLink('https://www.fxhash.xyz/generative/30058');
        } else {
          const data = await fetch(`https://api.tzkt.io/v1/domains?address=${cont[0][0]}`)
          const json = await data.json();
          if(json[0]?.name) {
            setOwner({name: json[0]?.name.replace('.', '. '), link: cont[0][0]})
          } else {
            setOwner({name: cont[0][0], link: cont[0][0]})
          }
          setOpenseaLink(`https://www.fxhash.xyz/gentk/KT1EfsNuqwLAWDd3o4pvfUx1CAh5GMdTrRvr-${169607 + iter}`);
        }


      }

    })()
  }, [chain, iter])
  let project_link;

  switch (chain) {
    case 'ethereum':
      project_link = 'https://www.fxhash.xyz/generative/0xe3da4c00a412d31eA16dE031d7E572423281577e';
      break;
    case 'tezos':
    default:
      project_link = 'https://www.fxhash.xyz/generative/30058'
      break;
  }
  const DataBlock = () => {
    if(is_tezos) {
      return <TezosData iter={iter}/>
    }
    return (
      <>
        {hash && <div>{hash}</div> }
        {opensea_link && (<a target='_blank' rel='noreferrer' href={opensea_link}>View on Open Sea</a>)}
        {!opensea_link && project_link && (<a target='_blank' rel='noreferrer' href={project_link}>Unowned</a>)}
        {owner.hash && (<a target='_blank' rel='noreferrer' href={`https://opensea.io/${owner.hash}`}>Owner: {owner.name || owner.hash}</a>)}
      </>
    )
  }

  return (
    <div className={`${chain} chain_page`}>
      <Link to={'/'} state={{'no_animation': true}} className="home">
        <img src={hello} style={{height: 45, width: 45}} alt=""/>
      </Link>
      <a href={'https://duaneking.medium.com/dialog-box-by-duane-king-scott-brewer-and-adam-moliski-abab7afd3519'} rel="noreferer" target="_blank" className="help">
        <img src={help} style={{height: 45, width: 45}} alt=""/>
      </a>
      <ScaleToFit className={dialog_classes} trigger={iter} key={word[0]+''+word[1]+' '+chain}>
        <div className="loading_bar">
          <div className="bar">
            <div className="progress"></div>
          </div>
        </div>
        <Link to={`/${prev_chain}/${prev}`}><button>{createSVG(word[0])}</button></Link>
        <Link onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} to={`/${next_chain}/${next}`}>
          <button onClick={() => setHover(false)}>{opt_hover ? createSVG(word[2], 3312) : createSVG(word[1])}</button>
        </Link>
      </ScaleToFit>

      <div className={'info'}>
        <div>Token <span style={{letterSpacing:2, marginLeft: -7}}>ID</span> {chain === 'optimism' ? 1 : iter}</div>
        {opensea_link === 'loading' ? (<div>Loading</div>) : (<DataBlock/>)}
      </div>
      <div className="icons">
        <Link to={'/optimism/1'}  className={'chainIcon'}>
          <img className="chainImage" alt="Optimism Logo" style={{opacity: chain==='optimism' ? 1:.3}} src={chain === 'optimism' ? opt_active : opt_flipped}/>
          <div className="label">Optimism</div>
        </Link>
        <Link to={'/ethereum/1'} className={'chainIcon'}>
          <img className="chainImage" alt="Ethereum Logo" style={{opacity: chain==='ethereum' ? 1:.3}} src={chain !== 'optimism' ? eth : eth_flipped}/>
          <div className="label">Ethereum</div>
        </Link>
        <Link to={'/tezos/1'} className={'chainIcon'}>
          <img className="chainImage" alt="Tezos Logo" style={{opacity: chain==='tezos' ? 1:.3}} src={chain !== 'optimism' ? tez : tez_flipped}/>
          <div className="label">Tezos</div>
        </Link>
      </div>
    </div>
  )
}