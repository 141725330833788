import './App.scss';
import hello from './img/hello.svg';
import {Home} from './Home.js'
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import {ChainView} from "./ChainView";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
const client = new ApolloClient({
  uri: "https://api.fxhash.xyz/graphql/",
  cache: new InMemoryCache()
});

function App() {
  const router = createBrowserRouter([{
    path: "/",
    element: <Home/>
  },
    {
      path: "/optimism/:iter",
      element: <ChainView chain={'optimism'}/>
    },{
      path: "/optimism",
      element: <ChainView chain={'optimism'}/>
    },{
      path: "/ethereum/:iter",
      element: <ChainView chain={'ethereum'}/>
    },{
      path: "/ethereum",
      element: <ChainView chain={'ethereum'}/>
    },{
      path: "/tezos/:iter",
      element: <ChainView chain={'tezos'}/>
    },{
      path: "/tezos",
      element: <ChainView chain={'tezos'}/>
    }]);

  return (
    <ApolloProvider client={client}>
      <div className="App">

        <div className="intro">
          <img className={'introImage'} src={hello} alt=""/>
        </div>
        <div className="page">
          <RouterProvider router={router}/>
        </div>
        <div className='scanlines'/>
      </div>
    </ApolloProvider>
  );
}

export default App;
