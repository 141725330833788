import {get_path} from "../letters";
import React from "react";
import {get_char_list} from "../tools/char_set";


const alphabet = get_char_list()
export function createSVG(enc_word, width_override = null) {
  const dec = (word) =>  JSON.parse(atob(word)).reduce((a,b) => a+(alphabet[b]||'-'), '');
  let width_total = 0;
  const word = dec(enc_word);
  const letters = Array.from(word).map((l, i) => {
    if (l === ' ') {
      width_total += 250;
      return '';
    }
    const e = get_path(l)
    const width = Math.max(...e.props.d.split(/[MLZ]+/g).splice(1).map(e => Number(e.trim().split(' ')[0])).filter(e => e))
    const result =  <g key={i} transform={`translate(${width_total},12)`}>{e}</g>;
    width_total += width + 114;
    return result;
  });
  return (
    <svg viewBox={`0 0 ${width_override || width_total} 640`}
         fillRule="evenodd"
         width={`${(width_override || width_total)* .04}px`}>{letters}</svg>
  )
}