import opt from "./img/opt.svg";
import tez from "./img/tez.svg";
import eth from "./img/eth.svg";

import {Link} from "react-router-dom";
import help from "./img/help.svg";
import React from "react";

export function Home() {
  console.log('fl', window.firstload);
  return (
    <>
      <a rel={'noreferrer'} href={'https://duaneking.medium.com/dialog-box-by-duane-king-scott-brewer-and-adam-moliski-abab7afd3519'} target="_blank" className="help">
        <img src={help} style={{height: 45, width: 45}} alt=""/>
      </a>
      <div className="dialog_box">
        <div className="dialog">
          <div className="title">
            <h1>Dialog Box</h1>
            <h2>Duane King, Scott Brewer, Adam Moliski</h2>
          </div>
          <div className="bottom">
            <div>
              <svg width="82" height="14" viewBox="0 0 82 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 9.5V5H2V9.5H0.5ZM2 11V9.5H5V5H2V3.5H5V0.499999H6.5V11H2ZM9.5 11V5H8V3.5H11V11H9.5ZM9.5 2V0.499999H11V2H9.5ZM12.5 9.5V8H14V9.5H12.5ZM14 11V9.5H17V8H14V6.5H17V5H18.5V11H14ZM14 5V3.5H17V5H14ZM21.5 11V2H20V0.499999H23V11H21.5ZM26 11V9.5H29V11H26ZM24.5 9.5V5H26V9.5H24.5ZM29 9.5V5H30.5V9.5H29ZM26 5V3.5H29V5H26ZM33.5 14V12.5H36.5V14H33.5ZM32 9.5V5H33.5V9.5H32ZM36.5 12.5V11H33.5V9.5H36.5V5H33.5V3.5H38V12.5H36.5ZM44 9.5V5H45.5V9.5H44ZM39.5 11V0.499999H41V3.5H44V5H41V9.5H44V11H39.5ZM48.5 11V9.5H51.5V11H48.5ZM47 9.5V5H48.5V9.5H47ZM51.5 9.5V5H53V9.5H51.5ZM48.5 5V3.5H51.5V5H48.5ZM54.5 11V9.5H56V11H54.5ZM60.5 11V9.5H62V11H60.5ZM56 9.5V8H57.5V9.5H56ZM59 9.5V8H60.5V9.5H59ZM57.5 8V6.5H59V8H57.5ZM56 6.5V5H57.5V6.5H56ZM59 6.5V5H60.5V6.5H59ZM54.5 5V3.5H56V5H54.5ZM60.5 5V3.5H62V5H60.5ZM65 11V9.5H66.5V11H65ZM69.5 11V9.5H72.5V11H69.5ZM68 9.5V5H69.5V6.5H72.5V5H74V8H69.5V9.5H68ZM69.5 5V3.5H72.5V5H69.5ZM75.5 11V9.5H80V11H75.5ZM80 9.5V8H81.5V9.5H80ZM77 8V6.5H80V8H77ZM75.5 6.5V5H77V6.5H75.5ZM77 5V3.5H81.5V5H77Z" fill="black" style={{fill:'black',fillOpacity:1}}/>
              </svg>
            </div>
            <div>
              <svg width="120" height="12" viewBox="0 0 120 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 12V10.5H9V12H3ZM1.5 10.5V9H3V10.5H1.5ZM9 10.5V9H10.5V10.5H9ZM4.5 9V7.5H7.5V9H4.5ZM3 7.5V4.5H4.5V7.5H3ZM0 9V3H1.5V9H0ZM4.5 4.5V3H7.5V4.5H4.5ZM10.5 9V3H12V9H10.5ZM1.5 3V1.5H3V3H1.5ZM9 3V1.5H10.5V3H9ZM3 1.5V0H9V1.5H3ZM18 12V10.5H19.5V9H21V10.5H25.5V12H18ZM21 9V7.5H22.5V9H21ZM22.5 7.5V6H24V7.5H22.5ZM18 4.5V3H19.5V4.5H18ZM24 6V3H25.5V6H24ZM19.5 3V1.5H24V3H19.5ZM28.5 12V10.5H33V12H28.5ZM27 10.5V3H28.5V10.5H27ZM33 10.5V3H34.5V10.5H33ZM28.5 3V1.5H33V3H28.5ZM36 12V10.5H37.5V9H39V10.5H43.5V12H36ZM39 9V7.5H40.5V9H39ZM40.5 7.5V6H42V7.5H40.5ZM36 4.5V3H37.5V4.5H36ZM42 6V3H43.5V6H42ZM37.5 3V1.5H42V3H37.5ZM46.5 12V10.5H51V12H46.5ZM45 10.5V9H46.5V10.5H45ZM51 10.5V7.5H52.5V10.5H51ZM46.5 7.5V6H48V4.5H49.5V6H51V7.5H46.5ZM49.5 4.5V3H45V1.5H52.5V3H51V4.5H49.5ZM63 7.5V6H64.5V7.5H63ZM61.5 6V4.5H63V6H61.5ZM64.5 6V4.5H66V6H64.5ZM58.5 12V1.5H60V3H61.5V4.5H60V12H58.5ZM67.5 12V4.5H66V3H67.5V1.5H69V12H67.5ZM72 12V10.5H75V12H72ZM70.5 10.5V6H72V10.5H70.5ZM75 10.5V6H76.5V10.5H75ZM72 6V4.5H75V6H72ZM79.5 12V10.5H82.5V12H79.5ZM78 10.5V6H79.5V10.5H78ZM82.5 10.5V6H84V10.5H82.5ZM79.5 6V4.5H82.5V6H79.5ZM90 12V6H91.5V12H90ZM85.5 12V4.5H90V6H87V12H85.5ZM93 12V10.5H97.5V12H93ZM97.5 10.5V9H99V10.5H97.5ZM94.5 9V7.5H97.5V9H94.5ZM93 7.5V6H94.5V7.5H93ZM94.5 6V4.5H99V6H94.5ZM105 12V6H106.5V12H105ZM100.5 12V1.5H102V4.5H105V6H102V12H100.5ZM109.5 12V10.5H112.5V12H109.5ZM108 10.5V6H109.5V10.5H108ZM112.5 10.5V6H114V10.5H112.5ZM109.5 6V4.5H112.5V6H109.5ZM118.5 12V10.5H120V12H118.5ZM117 10.5V6H115.5V4.5H117V3H118.5V4.5H120V6H118.5V10.5H117Z" fill="black" style={{fill:'black',fillOpacity:1}}/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className={`main_page icons ${window.firstload ? '' : ' noanim'}`}>
        <Link to={'/optimism'}  className={'chainIcon'}>
          <img className="chainImage" alt="Optimism Logo" src={opt}/>
          <div className="label">Optimism</div>
        </Link>
        <Link to={'/ethereum'} className={'chainIcon'}>
          <img className="chainImage" alt="Ethereum Logo" src={eth}/>
          <div className="label">Ethereum</div>
        </Link>
        <Link to={'/tezos'} className={'chainIcon'}>
          <img className="chainImage" alt="Tezos Logo" src={tez}/>
          <div className="label">Tezos</div>
        </Link>
      </div>
    </>
  )
}
